// import React, { useState, useEffect } from "react"
// import firebase from "gatsby-plugin-firebase"
// import SEO from "../components/seo"
// import Header from "../components/blogHeader"
// import { getDates, getTimeSlots } from "../db/helpers"
// import _ from "lodash"
// import Title from "../components/title"
// import Footer from "../components/footer"
// import { navigate } from "gatsby"
// import { Link } from "gatsby"
// import { FaSpinner } from "react-icons/fa"

// // Styles
// import styles from "../styles/components/reservations.module.scss"

// // Variables
// const numberOfReservations = 20

// const Reservations = () => {
//   const [data, setData] = useState()
//   const [name, setName] = useState("")
//   const [timeSlot, setTimeSlot] = useState("")
//   const [date, setDate] = useState("")
//   const [timeSlots, setTimeSlots] = useState("")
//   const [loading, setLoading] = useState(false)

//   useEffect(() => {
//     const dates = getDates()
//     fetchData(dates[0])
//     setDate(dates[0])
//     setTimeSlots(getTimeSlots(dates[0]))
//   }, [])

//   const fetchData = date => {
//     // Query DB for tomorrow's reservations
//     firebase
//       .database()
//       .ref(date)
//       .once("value")
//       .then(snapshot => {
//         if (!snapshot.exists()) {
//           console.log("No reservations for", date)
//           setData("")
//           return
//         }
//         let reservations = []

//         _.forEach(snapshot.val(), (value, key) => {
//           var arr = _.values(value)
//           reservations.push({ [key]: arr })
//         })
//         setData(reservations)
//       })
//       .catch(e => {
//         console.log("There was an error fetching data.", e)
//       })
//   }

//   const checkReservationExists = () => {
//     console.log("Checking if reservation exists")
//     fetchData(date)
//     _.forEach(data, value => {
//       _.forEach(value, namesArr => {
//         _.forEach(namesArr, arrName => {
//           if (
//             arrName
//               .toLowerCase()
//               .split(" ")
//               .join("") ===
//             name
//               .toLowerCase()
//               .split(" ")
//               .join("")
//           ) {
//             alert("A reservation with this name already exists for that date!")
//             setLoading(false)
//             throw new Error("Name already exists")
//           }
//         })
//       })
//     })
//     return false
//   }

//   const checkTimeSlotFull = () => {
//     // Check if time slot is full
//     fetchData(date)
//     _.forEach(data, value => {
//       if (value[timeSlot]) {
//         if (value[timeSlot].length > numberOfReservations - 1) {
//           alert(
//             "This Time Slot is already full. Please pick a different time slot."
//           )
//           setLoading(false)
//           throw new Error(
//             "This Time Slot is already full. Please pick a different time slot."
//           )
//         }
//       }
//       return false
//     })
//   }

//   const makeReservation = async e => {
//     setLoading(true)
//     e.preventDefault()
//     if (!name) {
//       console.log("Error: no name.")
//       alert("Please enter your name!")
//       setLoading(false)
//       return
//     }
//     if (!timeSlot) {
//       console.log("Error: Time Slot not selected.")
//       alert("Please select a time slot for your booking..")
//       setLoading(false)
//       return
//     }
//     // Check if reservation already exists
//     const reservationExists = await checkReservationExists()
//     const timeSlotFull = await checkTimeSlotFull()
//     if (!reservationExists && !timeSlotFull) {
//       // Make reservation
//       console.log("Making Reservation!")
//       var saveLocation = await firebase
//         .database()
//         .ref(date + "/" + timeSlot)
//         .push()
//         .catch(e => {
//           console.log("There was an error connecting to the database.", e)
//         })

//       var path = saveLocation.toString().split("/")
//       var reservationId = path[path.length - 1]

//       saveLocation
//         .set(name)
//         .then(() => {
//           console.log(
//             "Reservation saved successfully!",
//             `Name: ${name} Time: ${timeSlot}`
//           )

//           localStorage.setItem("name", name)
//           localStorage.setItem("timeSlot", timeSlot)
//           localStorage.setItem("date", date)
//           localStorage.setItem("reservationId", reservationId)

//           navigate("/reservation-success", {
//             state: {
//               name,
//               timeSlot,
//               date,
//               reservationId,
//             },
//           })
//         })
//         .catch(e => {
//           setLoading(false)
//           console.log("There was an error making this reservation.", e)
//         })
//     }
//   }

//   const onChangeHandler = async e => {
//     switch (e.target.name) {
//       case "name":
//         setName(e.target.value)
//         break
//       case "timeSlot":
//         setTimeSlot(e.target.value)
//         break
//       case "date":
//         setTimeSlots(getTimeSlots(e.target.value))
//         setTimeSlot("")
//         setDate(e.target.value)
//         fetchData(e.target.value)
//         break
//       default:
//         return
//     }
//   }

//   // Number of reservation slots available
//   const getSpotsRemaining = slot => {
//     let spotsRemaining = numberOfReservations
//     if (data) {
//       data.find(reservation => {
//         if (reservation[slot]) {
//           return (spotsRemaining -= reservation[slot].length)
//         }
//         return null
//       })
//     }

//     if (spotsRemaining <= 0) {
//       return false
//     } else if (spotsRemaining === 1) {
//       return `Only ${spotsRemaining} Spot Left`
//     }
//     return `${spotsRemaining} Spots Left`
//   }

//   return (
//     <>
//       <Header />
//       <SEO
//         title="Gym Reservations"
//         description="Due to the current situation with the pandemic, the gym can only be used with prior reservation."
//       />
//       <div className={styles.main}>
//         <div className={styles.content}>
//           <div className={styles.title}>
//             <Title text="Gym Reservation" />
//           </div>
//           <p className={styles.message}>
//             Please respect your time slot and ensure that you arrive and leave
//             according to your reservation and time slot.
//           </p>

//           <div className={styles.form}>
//             {/* Date */}
//             <div className={styles.dateInput}>
//               <h4 className={styles.formTitle}>Date:</h4>
//               <select
//                 value={date}
//                 className={styles.select}
//                 onChange={onChangeHandler}
//                 name="date"
//               >
//                 {getDates().map((date, idx) => (
//                   <option key={idx}>{date}</option>
//                 ))}
//               </select>
//             </div>

//             {/* Name */}
//             <div className={styles.nameInput}>
//               <h4 className={styles.formTitle}>Full Name</h4>
//               <p
//                 className={styles.error}
//                 style={!name ? {} : { display: "none" }}
//               >
//                 Name is required
//               </p>
//               <input
//                 required
//                 type="text"
//                 id="name"
//                 name="name"
//                 onChange={onChangeHandler}
//               />
//             </div>

//             {/* Time Slot */}
//             <h4 className={styles.formTitle}>Please Select Time Slot</h4>
//             <p
//               className={styles.error}
//               style={!timeSlot ? {} : { display: "none" }}
//             >
//               Time slot is required
//             </p>

//             {timeSlots &&
//               timeSlots.map((slot, i) => {
//                 const spotsRemaining = getSpotsRemaining(slot)
//                 return (
//                   <div className={styles.timeSlot} key={i}>
//                     <label htmlFor={slot} className={styles.container}>
//                       <span className={styles.spot}>{slot}</span>
//                       <span className={styles.spotsLeft}>
//                         {spotsRemaining ? spotsRemaining : "Time Slot Full"}
//                       </span>
//                       <input
//                         type="radio"
//                         id={slot}
//                         name="timeSlot"
//                         value={slot}
//                         onChange={onChangeHandler}
//                         disabled={!spotsRemaining}
//                       />
//                       <span className={styles.checkmark}></span>
//                     </label>
//                   </div>
//                 )
//               })}

//             <p className={styles.msg}>
//               If you are having any issues with making a reservation, please
//               email <a href="mailto:info@nugo.xyz">info@nugo.xyz</a>
//             </p>

//             <div className={styles.btnGroup}>
//               <Link to="/cancel-reservation">
//                 <button className={styles.btn2}>
//                   Cancel Prior Reservation
//                 </button>
//               </Link>

//               <button
//                 onClick={makeReservation}
//                 className={styles.btn}
//                 disabled={loading}
//               >
//                 {!loading ? (
//                   "Confirm Reservation"
//                 ) : (
//                   <FaSpinner className={styles.spinner} />
//                 )}
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   )
// }

// export default Reservations

import React from "react"
import SEO from "../components/seo"
import Header from "../components/blogHeader"
import Footer from "../components/footer"
import { Link } from "gatsby"

// Styles
import styles from "../styles/pages/success.module.scss"

const ClosedPage = () => (
  <>
    <Header />
    <SEO title="Make A Reservation" />
    <div className={styles.main}>
      <div className={styles.content}>
        <h1>Just Walk In!</h1>
        <p style={{ fontSize: 14 }}>
          We have temporarily disabled the reservation system!
        </p>
        <p> Kindly walk in to our gym at your convenience. Thank you!</p>
        <Link to="/">
          <button className={styles.button}>Go Back</button>
        </Link>
      </div>
    </div>
    <Footer />
  </>
)

export default ClosedPage
